import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { titleCase } from 'lib/utils';
import { LOAD_PARTICIPANT } from 'store/facilitatorDashboard/actions';
import { CloseButton } from 'react-bootstrap';
import CopyButton from 'components/shared/Button/CopyButton';
import Skeleton from 'components/shared/Skeleton';
import SanyasIcon from 'components/shared/SanyasIcon';
import './ParticipantInfoPopover.scss';

function ParticipantInfo({ participantId, displayName, popover, onClose }) {
  const facilitatedGroups = useSelector((state) => state.currentUser?.facilitatedGroups);
  const participant = useSelector((state) => state.facilitatorDashboard.participants.byId[participantId]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!participant?.id) {
      dispatch(LOAD_PARTICIPANT.request(participantId));
    }
  }, [participantId, popover, participant?.id, dispatch]);

  return (
    <div className={`participant-info hover-group ${popover ? '' : 'border rounded bg-white py-2 px-3 text-small'}`}>
      <div className="d-flex align-items-center">
        <div className="mb-0 fs-4 fw-semibold text-primary">
          {participant?.login || displayName || (participant?.error && 'Participant') || <Skeleton classes="title width-100 mb-0" />}
        </div>

        {!!participant?.login && (
          <CopyButton
            variant={null}
            size="sm"
            className="copy-button hover-item ms-2"
            result={participant.login}
          />
        )}
        {popover && <CloseButton className="ms-auto" onClick={onClose} />}

        {(!popover && facilitatedGroups?.includes(participant?.groupId)) && (
          <a
            href={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: participant?.id })}
            className="btn-plain hover-item ms-auto"
            target="_blank"
            rel="noreferrer"
          >
            <SanyasIcon name="boxArrowUpRight" size="lg" />
          </a>
        )}
      </div>

      {!participant?.error && (
        <>
          <div className="user-detail">
            <strong>{participant?.fullName || <Skeleton classes="text" />}</strong>
            <br />
            {participant?.decoratedCompletion || titleCase(participant?.completion) || <Skeleton classes="text" />}
            {' '}
            {participant?.percentageDoneCourse && `${participant?.percentageDoneCourse}%`}
          </div>

          <ul className="list-unstyled mb-2">
            <li>
              <span className="property-title">
                Ancestry
                {!!participant?.ancestryFacilitator && <SanyasIcon name="hummingbird" className="ms-1" />}
              </span>
              <span className="property-detail">
                {participant?.ancestryFacilitator || participant?.ancestry || <Skeleton classes="text" />}
              </span>
            </li>
            <li>
              <span className="property-title">Age</span>
              <span className="property-detail">{participant?.ageGroup || <Skeleton classes="text" />}</span>
            </li>
            <li>
              <span className="property-title">{participant?.healthAuthorityLabel || 'Health Authority'}</span>
              <span className="property-detail">
                {participant?.healthAuthority || <Skeleton classes="text" />}
                <span className="property-detail-secondary">{participant?.organization}</span>
              </span>
            </li>
            <li>
              <span className="property-title">Current Position</span>
              <span className="property-detail">{participant?.jobCategory || <Skeleton classes="text" />}</span>
            </li>
          </ul>
        </>
      )}

      {participant?.error && (
        <p className="text-danger">{participant?.error?.message}</p>
      )}

      {participant?.flag && (
        <div className="my-1 fw-semibold">
          <SanyasIcon name="flagFill" className="me-1 text-danger" />
          Flagged
          {/* <SanyasIcon name="flagFill" className={`me-1 ${FLAG_COLOURS[participant.flag]}`} />
          {participant.flag} */}
        </div>
      )}

      {!!participant?.iwlStatus?.status && (
        <div className="mb-2 fw-semibold">
          <SanyasIcon name="flowerSolid" className="me-1 text-accent-purple" />
          {`IWL ${participant?.iwlStatus?.status}`}
        </div>
      )}
    </div>
  );
}

ParticipantInfo.defaultProps = {
  displayName: null,
  popover: false,
  onClose: null,
};

ParticipantInfo.propTypes = {
  participantId: PropTypes.number.isRequired,
  displayName: PropTypes.string,
  popover: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ParticipantInfo;
