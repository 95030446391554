import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COURSE_PATH, FACILITATOR_COURSE_TYPE_PATH, FACILITATOR_CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE, LOAD_COURSE_GLOSSARY_ENTRIES } from 'store/courses/actions';
import { selectCourseById } from 'store/courses/selectors';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import Alert from 'react-bootstrap/Alert';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import SanyasIcon from 'components/shared/SanyasIcon';
import Skeleton from 'components/shared/Skeleton';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import GlossaryEntry from 'components/facilitator/Courses/GlossaryEntry';
import ClearableInput from 'components/shared/ClearableInput/ClearableInput';
import GlossaryEntryDetail from 'components/facilitator/Courses/GlossaryEntryDetail';

function CourseGlossaryPage() {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));
  const { allIds, byId } = useSelector((state) => state.courses.glossaryEntries);
  const [filteredIds, setFilteredIds] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(-1);

  useEffect(() => {
    dispatch(LOAD_FACILITATOR_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    if (!course?.id) {
      dispatch(LOAD_COURSE.request(courseId));
    }
  }, [course?.id, courseId, dispatch]);

  useEffect(() => {
    dispatch(LOAD_COURSE_GLOSSARY_ENTRIES.request({ courseId, workspace: 'facilitator' }));
  }, [courseId, dispatch]);

  useEffect(() => {
    setFilteredIds(allIds);
  }, [allIds]);

  useDocumentTitle(['Glossary', course?.displayName]);

  const handleChangeFilter = useCallback((e) => {
    if (!allIds?.length) return; // Nothing to filter

    const { value } = e.target;

    if (value?.length > 1) {
      const newFilteredIds = allIds.filter((id) => {
        const entry = byId[id];
        return entry.term.toLowerCase().includes(value.toLowerCase());
      });

      setFilteredIds(newFilteredIds);
    } else {
      setFilteredIds(allIds);
    }
  }, [allIds, byId]);

  return (
    <FacilitatorTemplate className="bg-white">
      <div className="header flush">
        <div className="d-flex align-items-center flex-col-gap-sm">
          <SanyasIcon name="book" size="3x" />
          <div>
            <Breadcrumbs
              navItems={[
                { label: 'Courses', href: FACILITATOR_CURRICULUM_PATH },
                { label: courseType?.name || <Skeleton classes="text" />, href: buildRoutePath(FACILITATOR_COURSE_TYPE_PATH, { id: courseType?.id }) },
                { label: course?.description || <Skeleton classes="text" />, href: buildRoutePath(FACILITATOR_COURSE_PATH, { courseId: course?.id }) },
              ]}
              ignoreLast
            />

            <h1>{courseType?.name || <Skeleton classes="title width-100" />}</h1>
          </div>
        </div>
      </div>

      <div className="flush bg-white">
        <div className="row mx-0 border-bottom">
          <div className="col-6 border-end px-0">
            <div className="d-flex align-items-center py-3 px-4 border-bottom">
              <h4 className="m-0">Glossary entries</h4>
            </div>

            <ul className="list-group list-group-flush">
              <li className="list-group-item ps-3">
                <ClearableInput placeholder="Filter by term" onChange={handleChangeFilter} />
              </li>

              {(allIds?.length > 0 && !filteredIds?.length) && (
                <li className="list-group-item ps-4">No entries found</li>
              )}
              {filteredIds?.map((entry) => (
                <GlossaryEntry key={entry} entry={byId?.[entry]} setSelectedEntry={setSelectedEntry} />
              ))}
            </ul>
          </div>

          <div className="col-6 px-0">
            {selectedEntry === -1 && (
              <div className="py-3 px-4">
                <Alert variant="light">
                  Click an entry to view its content.
                  <br />
                  You can also filter entries by title.
                </Alert>
              </div>
            )}
            {selectedEntry > -1 && (
              <GlossaryEntryDetail entry={selectedEntry} />
            )}
          </div>
        </div>
      </div>
    </FacilitatorTemplate>
  );
}

export default CourseGlossaryPage;
