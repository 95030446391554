import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import useQuery from 'lib/hooks/useQuery';
import { RESET_COURSES } from 'store/courses/actions';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectAvailableCourseTypes, selectCourseTypesLoading } from 'store/courseTypes/selectors';
import { selectOrganizationData, selectOrganizationsLoading } from 'store/organizations/selectors';
import { Alert } from 'react-bootstrap';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import Filters from 'components/curriculum/CurriculumFilters';
import Spinner from 'components/shared/Spinner';
import CourseTypes from 'components/facilitator/Courses/CourseTypes';

function FacilitatorCurriculum() {
  const filters = useQuery();
  const organizations = useSelector((state) => selectOrganizationData(state));
  const organizationsLoading = useSelector((state) => selectOrganizationsLoading(state));
  const courseTypes = useSelector((state) => selectAvailableCourseTypes(state));
  const courseTypesLoading = useSelector((state) => selectCourseTypesLoading(state));
  const dispatch = useDispatch();

  const loading = organizationsLoading || courseTypesLoading;

  const filteredOrganizations = useMemo(() => organizations.filter((org) => (filters.organization ? org.id === Number(filters.organization) : true)), [organizations, filters.organization]);

  const filteredCourseTypes = useMemo(() => courseTypes.filter((ct) => {
    const { query, organization, type } = filters;
    let filter = true;
    if (filter && query) {
      filter = query && ct.name.toLowerCase().includes(query.toLowerCase());
    }
    if (filter && organization) {
      filter = ct.organizationId === Number(organization);
    }
    if (filter && type) {
      filter = (type === 'core' && ct.isCore) || (type !== 'core' && !ct.isCore);
    }
    return filter;
  }), [courseTypes, filters]);

  useEffect(() => {
    dispatch(RESET_COURSES.action());
    dispatch(LOAD_FACILITATOR_COURSE_TYPES.request());
  }, [dispatch]);

  useDocumentTitle('Courses');

  return (
    <FacilitatorTemplate className="dashboard-page">
      <div className="header flush">
        <h1 className="mb-0">Courses</h1>
      </div>

      <div className="flush px-4">
        <div className="my-4">
          <Filters />
        </div>

        {loading && (<Spinner />)}

        {(!loading && filteredCourseTypes.length > 0) && filteredOrganizations.map(({ id, name }) => (
          <CourseTypes
            key={id}
            heading={name}
            organizationId={id}
            courseTypes={filteredCourseTypes}
          />
        ))}

        {(!loading && !filteredCourseTypes.length) && (
          <Alert variant="info">
            <span className="fas fa-info-circle fa-fw me-1" />
            No course types match the given filters.
          </Alert>
        )}
      </div>
    </FacilitatorTemplate>
  );
}

export default FacilitatorCurriculum;
