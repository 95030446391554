import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { FACILITATOR_CURRICULUM_PATH } from 'lib/routerPaths';
import { LOAD_COURSE_TYPE_COURSES } from 'store/courses/actions';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import SanyasIcon from 'components/shared/SanyasIcon';
import Badge from 'components/shared/Badge/Badge';
import CourseDrafts from 'components/facilitator/Courses/CourseDrafts';
import Skeleton from 'components/shared/Skeleton';
import CourseTable from '../../components/facilitator/Courses/CourseTable';

function CourseTypeShowPage() {
  const { id } = useParams();
  const courseType = useSelector((state) => selectCourseTypeById(state, id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LOAD_FACILITATOR_COURSE_TYPES.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LOAD_COURSE_TYPE_COURSES.request({ workspace: 'facilitator', id }));
  }, [id, dispatch]);

  useDocumentTitle(courseType?.name || 'Course');

  return (
    <FacilitatorTemplate>
      <div className="h-100 d-flex flex-column flush">
        <div className="header">
          <div className="d-flex align-items-center flex-col-gap-sm">
            <SanyasIcon name="book" size="3x" />
            <div>
              <Breadcrumbs
                navItems={[
                  { label: 'Courses', href: FACILITATOR_CURRICULUM_PATH },
                  { label: courseType?.name || <Skeleton classes="text" /> },
                ]}
              />

              <h1 className="d-flex align-items-center">
                {courseType?.name}
                {courseType && (
                  <Badge
                    variant={courseType.isCore ? 'green' : 'red'}
                    title={courseType.isCore ? 'Core' : 'Post-training'}
                    className="badge-xs ms-2"
                  />
                )}
              </h1>
            </div>
          </div>
        </div>

        <div className="m-4">
          <CourseDrafts />

          <h4 className="mt-4">Published</h4>
          <p>The course content that is visible to participants</p>

          <div className="mt-4">
            <CourseTable />
          </div>
        </div>
      </div>
    </FacilitatorTemplate>
  );
}

export default CourseTypeShowPage;
