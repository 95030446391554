import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Comment from 'components/shared/Comments/Comment';
import { Alert, Button } from 'react-bootstrap';
import { FLAG_COLOURS, POST_FLAGS } from 'lib/constants';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import useScrollToLocation from 'lib/hooks/useScrollToLocation';
import { LOAD_PARTICIPANT_JOURNAL, LOAD_JOURNAL_ANSWERS } from 'store/facilitatorDashboard/actions';
import { fetchSubGroupData, selectNoteByUserTask } from 'store/facilitatorDashboard/selectors';
import JournalHeader from 'components/facilitator/Journals/JournalHeader';
import JournalQuestion from 'components/shared/Journal/JournalQuestion';
import CommentCard from 'components/shared/Comments/CommentCard';
import SanyasIcon from 'components/shared/SanyasIcon';
import JournalAnswerCard from 'components/shared/Comments/JournalAnswerCard';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import NotesPanel from 'components/facilitator/NotesPanel';
import ParticipantInfo from 'components/group_memberships/ParticipantInfo';
import FlagForm from 'components/facilitator/Flag/FlagForm';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import Prioritized from 'components/facilitator/Prioritized';
import JournalAnswersSkeleton from './JournalAnswersSkeleton';

function JournalAnswersShowPage() {
  const params = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const { assignedFacilitatorId, questionIds, participantAnswerIds, participantAnswersById, commentIds, commentsById, journalPreviewsById } = useSelector((state) => state.facilitatorDashboard);
  const userTaskId = participantAnswersById?.[participantAnswerIds?.[0]]?.userTaskId;
  const note = useSelector((state) => selectNoteByUserTask(state, userTaskId));
  const subGroupData = useSelector((state) => fetchSubGroupData(state, params.subGroupId));
  const participant = useSelector((state) => state.facilitatorDashboard.participants?.byId[params.groupMembershipId]);
  const dispatch = useDispatch();
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;
  const loading = Number(params.groupMembershipId) !== Object.values(participantAnswersById || {})?.[0]?.participationId;
  const journal = subGroupData?.journalNotifications[params.taskId];

  const getAnswerId = (questionId) => participantAnswerIds.find((id) => participantAnswersById[id].questionId === questionId);
  const getCommentIds = (answerId) => commentIds.filter((id) => commentsById[id].commentableId === answerId);

  const [showFlagsModal, setShowFlagsModal] = useState(false);

  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const handleClose = () => setShowNotesPanel(false);
  const toggleShow = () => setShowNotesPanel((prev) => !prev);

  useEffect(() => {
    dispatch(LOAD_JOURNAL_ANSWERS.request(params));
  }, [params, dispatch]);

  useEffect(() => {
    if (!journalPreviewsById) {
      dispatch(LOAD_JOURNAL_ANSWERS.request(params));
    }
    dispatch(LOAD_PARTICIPANT_JOURNAL.request(params));
    setShowFlagsModal(false); // Updating the flag, updates journalPreviewsById, so close the modal
  }, [journalPreviewsById, params, dispatch]);

  useScrollToLocation();

  useDocumentTitle([
    participant?.fullName || 'Participant',
    `${journal?.name} (${journal?.internalName})`,
  ]);

  return (
    <FacilitatorTemplate className="bg-light">
      <JournalHeader className="mb-4" toggleNotes={toggleShow} />
      <NotesPanel context="userTask" contextId={userTaskId} show={showNotesPanel} onHide={handleClose} />

      {loading && <JournalAnswersSkeleton />}

      {!loading && questionIds?.map((questionId, questionIndex) => {
        const answerId = getAnswerId(questionId);
        const answer = answerId && participantAnswersById[answerId];
        return (
          <div key={questionId} className={`row ${questionIndex > 0 ? 'border-top pt-4' : ''}`}>
            <div className="col-7 offset-2 mb-3">
              <div className="mx-auto" style={{ maxWidth: 935 }}>
                <JournalQuestion questionId={questionId} />

                {(answerId && answer) && (
                  <>
                    <JournalAnswerCard answer={answer} />

                    {getCommentIds(answerId).map((commentId) => <CommentCard key={commentId} answerId={answerId} commentId={commentId} isJournalComment />)}

                    <div className="d-flex flex-column">
                      <Comment answer={answer} taskId={params.taskId} subGroupId={params.subGroupId} isJournal />
                    </div>
                  </>
                )}

                {(!answerId || !answer) && (
                  <Alert variant="info">
                    The participant has not answered this question yet.
                  </Alert>
                )}

              </div>
            </div>

            <div className="col-3">

              {(answer && isAssignedFacilitationPoint) && (
                <>
                  <Prioritized
                    key={userTaskId}
                    note={note || params}
                    assigned={isAssignedFacilitationPoint}
                    text="Starred"
                    variant="alt-outline"
                    size="sm"
                    className="d-block text-nowrap mb-2"
                  />

                  <SimpleToolTip placement="top" text={answer.flagged ? (answer.flag || POST_FLAGS.FLAGGED_FOR_REVIEW) : 'Not flagged'}>
                    <Button variant="alt-outline" size="sm" className="text-nowrap mb-2" onClick={() => setShowFlagsModal(true)}>
                      <SanyasIcon name="flag" className={`me-1 ${answer.flagged ? FLAG_COLOURS[answer.flag || POST_FLAGS.FLAGGED_FOR_REVIEW] : 'journal-flag'}`} />
                      Flag
                    </Button>
                  </SimpleToolTip>

                  <FlagForm post={answer} show={showFlagsModal} onClose={setShowFlagsModal} />
                </>
              )}

              {(answer && !isAssignedFacilitationPoint) && (
                <div className="mb-2">
                  <SimpleToolTip placement="top" text={answer.flag ?? 'Not flagged'}>
                    <span className="border border-secondary rounded bg-white py-1 px-2 text-small">
                      <SanyasIcon name="flag" className={`me-1 ${answer.flagged ? FLAG_COLOURS[answer.flag || POST_FLAGS.FLAGGED_FOR_REVIEW] : 'journal-flag'}`} />
                      Flag
                    </span>
                  </SimpleToolTip>
                </div>
              )}

              {questionIndex === 0 && (
                <ParticipantInfo participantId={Number(params.groupMembershipId)} />
              )}
            </div>
          </div>
        );
      })}
    </FacilitatorTemplate>
  );
}

export default JournalAnswersShowPage;
