import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { STATUS_BADGE_VARIANTS } from 'lib/constants';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_CONVERSATION_PATH, FACILITATOR_MESSAGES_NEW_PATH, FACILITATOR_PARTICIPANTS_MESSAGES_PATH } from 'lib/routerPaths';
import { titleCase } from 'lib/utils';
import ParticipantBadge from 'components/shared/Badge/ParticipantBadge';
import Badge from 'components/shared/Badge/Badge';

function ParticipantHeader() {
  const { path, params } = useRouteMatch();
  const currentUser = useSelector((state) => state.currentUser);
  const conversation = useSelector((state) => state.messages.show.conversation);
  const firstMessage = conversation?.messages?.[0];
  const participant = firstMessage?.toUser?.id === currentUser?.id ? firstMessage?.fromUser : firstMessage?.toUser;

  let documentTitle = 'Messages';
  if (path === FACILITATOR_CONVERSATION_PATH && participant) {
    documentTitle = `${participant.fullName} - ${conversation.subject}`;
  }
  if (path === FACILITATOR_MESSAGES_NEW_PATH) {
    documentTitle = 'Compose New Message';
  }

  useDocumentTitle(documentTitle);

  if (path !== FACILITATOR_CONVERSATION_PATH || !participant) {
    return null;
  }

  return (
    <>
      <p className="mb-0">
        <Link className="btn-plain fw-semibold" to={`${buildRoutePath(FACILITATOR_PARTICIPANTS_MESSAGES_PATH, { groupMembershipId: participant.groupMembershipId })}/${params.conversationId}`}>
          {participant.fullName}
        </Link>
        <span className="ms-2 text-small">
          <ParticipantBadge
            state={participant.groupMembershipCompletion}
            completion={participant.groupMembershipDecoratedCompletion}
          />
        </span>
        <br />
        <span className="text-small">{participant.subGroupName}</span>
      </p>
      <p className="ms-4 mb-0 border-start ps-4">
        {participant.groupName}
        <br />
        <span className="status text-small">
          <Badge
            variant={STATUS_BADGE_VARIANTS[participant.groupState] || STATUS_BADGE_VARIANTS.default}
            title={titleCase(participant.groupState)}
          />
        </span>
      </p>
    </>
  );
}

export default ParticipantHeader;
