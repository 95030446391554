import React from 'react';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import SanyasIcon from 'components/shared/SanyasIcon';
import LiveFeed from 'components/facilitator/LiveFeed/LiveFeed';

function LiveFeedPage() {
  useDocumentTitle('Live Feed');

  return (
    <FacilitatorTemplate className="dashboard-page">
      <div className="header flush">
        <span className="d-flex align-items-center">
          <SanyasIcon name="textCenter" className="me-2" size="xl" />
          <h1 className="mb-0">Live Feed</h1>
        </span>
      </div>

      <div className="flush mt-4 px-4">
        <LiveFeed />
      </div>
    </FacilitatorTemplate>
  );
}

export default LiveFeedPage;
