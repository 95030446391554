import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import useQuery from 'lib/hooks/useQuery';
import { LOAD_INITIAL_STATE } from 'store/facilitatorDashboard/actions';
import { sortedSubGroups } from 'store/facilitatorDashboard/selectors';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import PlatformStatus from 'components/shared/PlatformStatus';
import ErrorMessage from 'components/shared/ErrorMessage';
import Spinner from 'components/shared/Spinner';
import FacilitatorHeader from 'components/facilitator/FacilitatorHeader';
import SubGroup from 'components/facilitator/SubGroup';
import './styles.scss';

function FacilitatorDashboard() {
  const currentUser = useSelector((state) => state.currentUser);
  const subGroups = useSelector((state) => sortedSubGroups(state));
  const { loadingFacilitatorData, currentFacilitator, error } = useSelector((state) => state.facilitatorDashboard);
  const dispatch = useDispatch();
  const { facilitator } = useQuery();

  useEffect(() => {
    if (!currentFacilitator?.id && currentUser?.id) {
      dispatch(LOAD_INITIAL_STATE.request({ id: facilitator }));
    }
  }, [currentFacilitator?.id, currentUser?.id, facilitator, dispatch]);

  useDocumentTitle('Facilitator Dashboard');

  return (
    <FacilitatorTemplate className="dashboard-page">
      <PlatformStatus />

      <FacilitatorHeader />

      <ErrorMessage error={error} className="mt-4" />

      { (!error && (!subGroups || loadingFacilitatorData)) && <Spinner className="mt-3" message="Loading" /> }
      { (!loadingFacilitatorData && subGroups && _isEmpty(subGroups)) && <div className="mt-3">This facilitator is not assigned to any pods.</div> }
      { (!loadingFacilitatorData && subGroups)
        && (
        <>
          {
            _map(subGroups, ({ subGroupId }) => <SubGroup key={subGroupId} subGroupId={subGroupId} />)
          }
        </>
        )}
    </FacilitatorTemplate>
  );
}

export default FacilitatorDashboard;
