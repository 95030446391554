// https://usehooks.com/usedocumenttitle
import { useEffect } from 'react';

export function useDocumentTitle(title) {
  let localTitle = title;
  if (Array.isArray(title)) {
    localTitle = title.filter((v) => v?.trim()).join(' - ');
  }

  useEffect(() => {
    document.title = `${localTitle}${localTitle ? ' - ' : ''}San'yas`;

    return () => {
      document.title = 'San\'yas';
    };
  }, [localTitle]);
}
