import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS } from 'lib/constants';
import { FACILITATOR_CONVERSATION_PATH, FACILITATOR_MESSAGES_PATH } from 'lib/routerPaths';
import { parseSubGroupName } from 'lib/utils';
import { SET_PRESELECTED_USER_ID, SEND_MESSAGES } from 'store/messages/actions';
import { Alert, Button, Spinner as RBSpinner } from 'react-bootstrap';
import Spinner from 'components/shared/Spinner';
import SelectField from 'components/shared/FormFields/SelectField';
import InputField from 'components/shared/FormFields/InputField';
import TextareaField from 'components/shared/FormFields/TextareaField';
import SanyasIcon from 'components/shared/SanyasIcon';
import { buildRoutePath } from 'lib/routerHelpers';

const FormSchema = Yup.object().shape({
  recipients: Yup.array().of(Yup.string()).required('Required').min(1, 'Required'),
  subject: Yup.string().required('Required').typeError('Required'),
  message: Yup.string().required('Required'),
});

const subGroupLabel = (subGroup) => {
  if (!subGroup) return '';

  const { pod } = parseSubGroupName(subGroup.name);
  return `${subGroup.groupName} - ${pod}`;
};

const groupRecipients = (subGroups, recipients) => {
  const result = [];
  if (!subGroups || !recipients) {
    return result;
  }

  subGroups.forEach((subGroup) => {
    const label = subGroupLabel(subGroup);
    const subGroupRecipients = recipients.filter((r) => r.subGroupId === subGroup.id).map((r) => ({ value: r.id, label: r.fullName, subGroup: label }));
    result.push({
      label,
      options: subGroupRecipients,
    });
  });
  return result;
};

const formatOptionLabel = ({ label, subGroup }, { context }) => {
  if (context === 'menu') {
    return label;
  }

  return (
    <>
      <span className="fw-semibold">{label}</span>
      <span className="ps-2 text-secondary text-uppercase" style={{ fontSize: '0.75em' }}>{subGroup}</span>
    </>
  );
};

function MessagesNewPage({ userId }) {
  const { availableRecipients, availableSubGroups, conversationId, status } = useSelector((state) => state.messages.compose);
  const preSelectedRecipient = (availableRecipients && userId && availableRecipients.find((r) => r.userId === userId));
  const preSelectedSubGroup = (availableSubGroups && preSelectedRecipient && availableSubGroups.find((sg) => sg.id === preSelectedRecipient.subGroupId));
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const groupedRecipients = groupRecipients(availableSubGroups, availableRecipients);

  useEffect(() => {
    if (userId) {
      dispatch(SET_PRESELECTED_USER_ID.action({ userId }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (conversationId && status === REDUX_STATUS.SUCCESS) {
      history.push(buildRoutePath(FACILITATOR_CONVERSATION_PATH, { conversationId }));
    }
  }, [conversationId, status, history]);

  const sendMessages = (values, actions) => {
    dispatch(SEND_MESSAGES.request(values, { actions }));
  };

  if (!currentUser || !availableRecipients) {
    return <Spinner message="Loading..." />;
  }

  return (
    <>
      { availableSubGroups.length === 0 && (
        <Alert variant="warning" className="mt-3">You cannot compose a message to participants, because you are not currently facilitating any groups.</Alert>
      )}

      { availableSubGroups.length > 0 && (
        <Formik
          initialValues={{
            recipients: preSelectedRecipient ? [preSelectedRecipient.id] : [],
            subject: '',
            message: '',
            isFlagged: false,
          }}
          onSubmit={sendMessages}
          validationSchema={FormSchema}
        >
            {({ values, isSubmitting, setFieldValue }) => (
              <Form className="card border shadow-sm mt-4">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    {preSelectedRecipient ? (
                      <span>
                        <span className="fw-semibold">{preSelectedRecipient?.fullName}</span>
                        <span className="ps-2 text-secondary text-uppercase" style={{ fontSize: '0.75em' }}>{subGroupLabel(preSelectedSubGroup)}</span>
                      </span>
                    ) : (
                      <SelectField
                        multiple
                        inline
                        name="recipients"
                        label="To"
                        hideLabel
                        placeholder="Select recipients"
                        required
                        options={groupedRecipients}
                        formatOptionLabel={formatOptionLabel}
                        className="w-100 me-3"
                        showTooltip
                      />
                    )}

                    <div className="ms-auto">
                      <Button variant="plain" size="sm" className="text-nowrap" onClick={() => setFieldValue('isFlagged', !values.isFlagged)}>
                        <SanyasIcon name={values.isFlagged ? 'flagFill' : 'flag'} className={`me-1 ${values.isFlagged ? 'text-warning' : ''}`} />
                        Flag
                      </Button>
                    </div>
                  </div>

                  <InputField
                    name="subject"
                    label="Subject"
                    placeholder="Subject"
                    required
                  />

                  <TextareaField
                    name="message"
                    label="Message"
                    rows="8"
                    placeholder="Type your message here"
                    required
                  />

                  <div className="d-flex justify-content-end">
                    <Button type="submit" variant="primary" className="order-2" disabled={isSubmitting}>
                      Send
                      {isSubmitting && <RBSpinner size="sm" className="ms-1" animation="border" role="status" />}
                    </Button>
                    <Link to={FACILITATOR_MESSAGES_PATH} className="order-1 btn btn-plain me-1">Cancel</Link>
                  </div>
                </div>
              </Form>
            )}
        </Formik>
      )}
    </>
  );
}

const RecipientPropType = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.number,
  fullName: PropTypes.string,
  subGroupId: PropTypes.number,
});

MessagesNewPage.defaultProps = {
  userId: null,
  preSelectedRecipient: null,
};

MessagesNewPage.propTypes = {
  userId: PropTypes.number,
  preSelectedRecipient: RecipientPropType,
};

export default MessagesNewPage;
