import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { LOAD_INITIAL_STATE } from 'store/facilitatorDashboard/actions';
import Spinner from 'components/shared/Spinner';
import FacilitatorHeader from 'components/facilitator/FacilitatorHeader';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import DiscussionBoardsTable from 'components/facilitator/DiscussionBoards/DiscussionBoardsTable';
import SanyasIcon from 'components/shared/SanyasIcon';

function DiscussionBoardsPage() {
  const currentUser = useSelector((state) => state.currentUser);
  const { loadingSubGroups, currentFacilitator } = useSelector((state) => state.facilitatorDashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentFacilitator?.id && currentUser?.id) {
      dispatch(LOAD_INITIAL_STATE.request());
    }
  }, [currentFacilitator?.id, currentUser?.id, dispatch]);

  useDocumentTitle('Discussion Boards');

  return (
    <FacilitatorTemplate className="dashboard-page">
      <div className="header flush">
        <span className="d-flex align-items-center text-accent-purple">
          <SanyasIcon name="discussion" className="me-2" size="xl" />
          <h1 className="mb-0">Discussion Boards</h1>
        </span>

      </div>

      <div className="flush px-4">
        <FacilitatorHeader />

        {loadingSubGroups
          ? <div className="mt-3"><Spinner message="Loading" /></div>
          : <DiscussionBoardsTable />}
      </div>
    </FacilitatorTemplate>
  );
}

export default DiscussionBoardsPage;
