import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { CREATE_FACILITATOR_OVERVIEW_REPORT, GET_FACILITATOR_OVERVIEW_FORM_DATA } from 'store/superFacilitatorReport/actions';
import Button from 'react-bootstrap/Button';
import SelectField from 'components/shared/FormFields/SelectField';
import DatePickerField from 'components/shared/FormFields/DatePickerField';
import Skeleton from '../FacilitatorReports/FormSkeleton';

const FormSchema = Yup.object().shape({
  reportType: Yup.string().required('Required'),
});

function RequestForm() {
  const dispatch = useDispatch();
  const { formLoading, formData } = useSelector((state) => state.superFacilitatorReport);
  useEffect(() => {
    dispatch(GET_FACILITATOR_OVERVIEW_FORM_DATA.request());
  }, [dispatch]);

  const handleSubmit = useCallback((values, { resetForm }) => {
    dispatch(CREATE_FACILITATOR_OVERVIEW_REPORT.request(values));
    resetForm();
  }, [dispatch]);

  if (formLoading) return <Skeleton />;

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      initialValues={{
        reportType: 'facilitator_overview',
        facilitatorIds: [],
        reportInterval: 'weekly',
        facilitationPoint: ['all'],
      }}
    >
      {({ resetForm }) => (
        <Form>
          <div className="row">
            <div className="col-md-8 col-xl-6">
              <SelectField
                name="reportInterval"
                label="Report Interval"
                options={[
                  { value: 'weekly', label: 'Weekly' },
                  { value: 'monthly', label: 'Monthly' },
                  { value: 'quarterly', label: 'Quarterly' },
                ]}
                required
              />

              <div className="row">
                <div className="col-md-6">
                  <DatePickerField
                    name="startDate"
                    label="Report Timeframe"
                    placeholder="Pick start date (optional)"
                    dateFormat="m/d/Y"
                  />
                </div>
                <div className="col-md-6">
                  <DatePickerField
                    name="endDate"
                    label="to:"
                    placeholder="Pick end date (optional)"
                    dateFormat="m/d/Y"
                  />
                </div>
              </div>

              <SelectField
                name="facilitatorIds"
                label="Facilitators"
                placeholder="Filter by facilitator (optional)"
                options={formData.facilitators}
                multiple
              />
              <SelectField
                name="facilitationPoint"
                label="Facilitation point"
                multiple
                options={[
                  { value: 'discussion_boards', label: 'Discussion boards' },
                  { value: 'journals', label: 'Journals' },
                  { value: 'messages', label: 'Messages' },
                  { value: 'all', label: 'All' },
                ]}
                required
              />
            </div>
          </div>

          <div className="my-3">
            <Button variant="primary" type="submit" className="me-2">
              Request this report
            </Button>

            <Button variant={null} className="btn-plain" onClick={resetForm}>
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RequestForm;
